
import { Component, Vue } from "vue-property-decorator";

import {
  BarcodeScanner,
  BarcodeFormat,
  GoogleBarcodeScannerModuleInstallState
} from "@capacitor-mlkit/barcode-scanning";

@Component
export default class QrRegistrationButtonComponent extends Vue {
  isBarcodeScannerSupported = true;
  isBarcodeScannerAvailable = false;

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  async created() {
    if (this.deviceInfo.platform == "web") return;

    await BarcodeScanner.removeAllListeners();

    const { supported } = await BarcodeScanner.isSupported();
    this.isBarcodeScannerSupported = supported;

    if (this.deviceInfo.platform == "android") {
      await this.installGoogleBarcodeScannerModule();
    }

    if (this.deviceInfo.platform == "ios") {
      this.isBarcodeScannerAvailable = true;
    }
  }

  async installGoogleBarcodeScannerModule() {
    const { available } = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
    this.isBarcodeScannerAvailable = available;

    if (this.isBarcodeScannerSupported && !available) {
      BarcodeScanner.addListener("googleBarcodeScannerModuleInstallProgress", async ({ state }) => {
        if (state == GoogleBarcodeScannerModuleInstallState.COMPLETED) {
          this.isBarcodeScannerAvailable = true;
        }
      });

      await BarcodeScanner.installGoogleBarcodeScannerModule();
    }
  }

  async scanBarcode() {
    const state = await this.requestBarcodeScannerPermissions();

    if (state != "granted" && state != "limited") {
      this.$store.commit("snack/setSnack", {
        status: "error",
        text: "Check camera permissions"
      });

      return;
    }

    const { barcodes } = await BarcodeScanner.scan({
      formats: [BarcodeFormat.QrCode]
    });

    if (barcodes.length != 0) {
      this.$router.push({ name: "qr-registration", params: { uuid: barcodes[0].rawValue } });
    }
  }

  async requestBarcodeScannerPermissions() {
    const { camera } = await BarcodeScanner.requestPermissions();

    return camera;
  }

  // async openSystemAppSettings() {
  //   await BarcodeScanner.openSettings();
  // }
}
